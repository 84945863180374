import React from 'react';
import './contact-us.scss';
import Typography from "@mui/material/Typography";
import { Button } from '@mui/material';
import { RiTimeFill } from 'react-icons/ri';
import { MdAlternateEmail } from 'react-icons/md';

const ContactUs = () => {


    return (
        <div className="sectionContainer full-width-element">
            <div className="contactUs">
                <h1 className="bannerHeading tertiary-dark-text">Contact Us</h1>
                {/* <h2 className="subtitle2">Please choose your preferred method for talking with us</h2> */}
                <div className="optionContainer">
             
                        {/* <div className='option' style={{ display: "grid", justifyItems: "center", gridRowGap: '1rem' }}>
                            <lottie-player
                                src="/lottie-files/google-logo.json"
                                background="transparent"
                                speed="1"
                                style={{ width: 200, height: 200 }}
                                autoplay
                            ></lottie-player>
                            <h5 className="header">Google Meeting</h5>
                            <Typography variant="body2" color="text.secondary" className='body'>
                                Pick a time that suits you for a 20 minute google meeting with one of our senior consultants.
                            </Typography>
                            <a href="https://calendar.app.google/1gCWy6CNrMPb65Z36" target="_blank" rel="noreferrer">
                                <Button variant="contained" className='subtitle2 btn btn-tertiary callToAction' sx={{ textTransform: 'unset' }} endIcon={<RiTimeFill />}>Pick your slot</Button>

                            </a>
                        </div> */}

                        <div className='option' style={{ display: "grid", justifyItems: "center", gridRowGap: '1rem' }}>
                            <lottie-player
                                src="/lottie-files/email.json"
                                background="transparent"
                                speed="1"
                                style={{ width: 200, height: 200 }}
                                autoplay
                            ></lottie-player>
                            <h5 className="header">Email</h5>

                            <Typography variant="body2" color="text.secondary" className='body'>
                                Send us an email to <a href="mailto: enquiries@kzen8.com" target="_blank" rel="noreferrer"><span className="primary-text"> enquiries@kzen8.com</span></a> and we will get back to you as soon as possible.
                            </Typography>
                            <a href="mailto: enquiries@kzen8.com" target="_blank" rel="noreferrer">
                                <Button variant="contained" className='subtitle2 btn btn-tertiary callToAction' sx={{ textTransform: 'unset' }} endIcon={<MdAlternateEmail />}>Send email</Button>
                            </a>


                        </div>
              
              
                </div>

            </div>
        </div>

    );
}

export default ContactUs;
