import { Button } from "@mui/material";
import { useWindowSize } from "@react-hook/window-size/throttled";
import React, { useEffect, useState } from "react";
import { BsClockFill, BsDownload, BsFillArrowRightCircleFill } from "react-icons/bs";
import { Link, useNavigate, useParams } from "react-router-dom";
import StrokePattern from "../../../components/stroke-pattern/stroke-pattern";
import "./solution.scss";
import { solutions } from "../solutions-array";
import Features from "../../../sections/features/features";

const Solution = () => {
  const [width, height] = useWindowSize();
  const navigate = useNavigate();
  const params = useParams();

  const solution = solutions.find(sol => sol.id === params.solution);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!solution) {
    navigate('/');
  }
  else {
    return (
      <div className="solution full-width-element">

        <div className="topDescription app-background-text-colour">
          <section className="left">
            <h1 className="bigHeading app-background-text-colour">{solution.title}</h1>

            <span className="body app-background-text-colour">
              {solution.firstParagraph}

              <br />
              <br />

            </span>

            {solution.collaboratorLogoLink ? <img className="collaboratorLogo" src={solution.collaboratorLogoLink} alt={solution.title} /> : ''}
          </section>
          <section className="right"></section>
          <img src={solution.heroImageLink} alt="solutions hero page" className="heroImage" />
        </div>

        <div className="solutionBox">

          <div className="contentBox">
            <section className="left">
              <h1 className="header">What is {solution.title}</h1>

              <p className="body">
                {solution.definition}
              </p>

              <div className="featuresBox">
                {solution.features?.map((feature, idx) => {
                  return <div className="feature">
                    <span className="body accent">✓</span>
                    <span className="body">{feature.title}</span></div>
                })}
              </div>

              {solution.downloadLink ? <a href={solution.downloadLink} target="_blank" download>
                <Button variant="contained" className="btn btn-tertiary subtitle2 callToAction" endIcon={<BsDownload />}>Learn More</Button>
              </a> : ''}
            </section>

            <section className="right">
              <img src={solution.imageLink} alt="solutions hero page" className="heroImage" />
            </section>
          </div>


        </div>

        <div className="benefitsBox">
          <h2 className="header">Benefits</h2>

          <div className="contentBox">


            {solution.benefits?.map((benefit, idx) => (
              <div className="benefit">
                <h2 className="subtitle2 accent">{idx + 1}.</h2>
                <h2 className="headerSmall">{benefit.title}</h2>
                <span className="body">{benefit.body}</span>
              </div>
            ))}
          </div>

        </div>

      </div>
    );
  }


};

export default Solution;
