import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import SideBar from "../side-bar/side-bar";
import "./top-bar.scss";
import { useWindowSize } from "@react-hook/window-size/throttled";
import styles from './top-bar.module.scss';
import { Button } from "@mui/material";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { useContext } from "react";
import UserPreferenceContext from "../../contexts/user-preference-context";
import { GiHamburgerMenu } from "react-icons/gi";
import { ImCross } from "react-icons/im";

const TopBar = ({ setUserPreference }) => {
  const player = useRef(null);
  const player2 = useRef(null);
  const [sideBarOpen, setSideBarOpen] = useState(null);
  const [timer, setTimer] = useState(null);
  const location = useLocation();

  console.log(location);


  const [width, height] = useWindowSize();

  const [playerSize, setPlayerSize] = useState("768px");

  const userPreference = useContext(UserPreferenceContext);


  useEffect(() => {
    if (player) {
      player.current?.addEventListener("complete", (evt) => {
        player.current?.stop();
      });
    }
  }, [player]);

  useEffect(() => {
    if (player2) {
      player2.current?.addEventListener("complete", (evt) => {
        player2.current?.stop();
      });
    }
  }, [player2]);

  const onSideBarClick = () => {
    setSideBarOpen(!sideBarOpen);
  };

  useEffect(() => {
    if (sideBarOpen !== null) {
      clearTimeout(timer);
      player.current?.setDirection(sideBarOpen ? 1 : -1);
      player.current?.play();

      player2.current?.setDirection(sideBarOpen ? 1 : -1);
      player2.current?.play();
    }

    if (sideBarOpen) {
      setTimer(
        setTimeout(() => {
          player.current?.pause();
          player2.current?.pause();
        }, 1400)
      );
    }
  }, [sideBarOpen]);

  useEffect(() => {
    if (width > 550) {
      setPlayerSize("64px");
    } else {
      setPlayerSize("54px");
    }
  }, [width]);


  const getLogoLink = () => {
    if (location.pathname.startsWith('/solutions')) {
      return '/images/logo-new.png';
    }
    else {
      return '/images/logo.png';
    }
  }

  const getMenuIcon = () => {
    if (sideBarOpen) {
      return <ImCross className="bigHeading"  onClick={onSideBarClick} />
    }


    if (location.pathname.startsWith('/solutions')) {
      return <GiHamburgerMenu className="app-background-text-colour bigHeading" onClick={onSideBarClick} />

    }
    else {
      return <GiHamburgerMenu className="bigHeading" onClick={onSideBarClick} />

    }
  }



  return (
    <div className={`topBar full-width-element app-container ${location.pathname.startsWith('/solutions') ? 'alternate' : ''}`}>
      <div className="leftSection">
        <Link className="" to="/">
          <img src={getLogoLink()} alt="logo" />
        </Link>
      </div>
      <div className="rightSection">
        <ul>
          <li className="body">
            <Link className="" to="/">Home</Link>
          </li>

          <li className="body">
            <Link className="" to="/platform">Platform</Link>
          </li>

          <li className="body">
            <Link className="" to="/solutions">Solutions</Link>
          </li>

          <li className="body">
            <Link className="" to="/partner">Partners</Link>
          </li>

          <li className="body">
            <Link className="" to="/pricing">Pricing</Link>
          </li>
          <li className="body">
            <Link className="" to="/contact-us">Contact Us</Link>
          </li>

          <li className="body">

            <a href="https://community.kzen8.com" target="_blank" rel="noopener noreferrer">
              <Button className="btn btn-tertiary body " style={{ colour: 'white' }} variant="contained" endIcon={<BsFillArrowRightCircleFill />}>
                Community
              </Button>
            </a>
          </li>
        </ul>

        <span className="burgerMenuButton">

          {getMenuIcon()}

        </span>
      </div>

      <SideBar open={sideBarOpen} setSideBarOpen={setSideBarOpen} />
    </div>
  );
};







export default TopBar;
